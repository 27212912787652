// export enum ReportDataType {
//     Text = 0,
//     LongText = 1,
//     Number = 2,
//     DatePicker = 3,
//     Media = 4,
//     Dropdown = 5,
//     MultipleDropdown = 6,
//     LinkedType = 7,
//     Bool = 8,
//     BoolAndLongText = 9,
//     BoolAndLongTextAndMedia = 10,
//     LongTextAndMedia = 11,
//     TextAndDatePicker = 12,
//     DropdownAndDatePicker = 13,
//     MultipleDropdownAndLongText = 14,
//     BoolAndDropdownAndLongText = 15,
//     DropdownAndLongText = 16,
// }

export default [
    {
        name: "",
        title: "1.0 BOARD STRUCTURE AND COMPOSITION",
        description: "",
        questions: [
            {
                question: "The procedure for appointment to the Board shall be formal, transparent, and documented in the Board Charter.",
                isRequired: true,
                uniqueName: "R1Q1",
                entryName: "r1q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Members of the Board shall be appointed by the shareholders of the bank and approved by the CBN.",
                isRequired: true,
                uniqueName: "R1Q2",
                entryName: "r1q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The minimum and maximum number of directors on the Boards of Commercial, Merchant and Non-Interest Banks (CMNIBs) shall be seven (7) and fifteen (15) respectively. <br /> For a Payment Service Bank (PSB), the minimum and maximum number of directors on the board shall be seven (7) and thirteen (13).",
                isRequired: true,
                uniqueName: "R1Q3",
                entryName: "r1q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Board shall consist of Executive and Non-Executive Directors. The number of Non-Executive Directors shall be more than Executive Directors on the Board and its Committees.",
                isRequired: true,
                uniqueName: "R1Q4",
                entryName: "r1q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The number of Independent Non-Executive Directors (INEDs) shall be at least: <br /> <br /> Three for: <br /> i. commercial banks with international and national authorization <br /> ii. merchant banks <br /> iii. Non-Interest Banks (NIBs) with national authorization. <br /> <br /> Two for: <br /> i. PSBs <br /> ii. commercial banks with regional authorization; and <br /> iii. NIBs with regional authorization.",
                isRequired: true,
                uniqueName: "R1Q5",
                entryName: "r1q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "In the case of publicly listed banks, the provisions of Companies and Allied Matters Act (CAMA) 2020, on the number of INEDs shall apply.",
                isRequired: true,
                uniqueName: "R1Q6",
                entryName: "r1q6",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "At least two Non- Executive Directors (NEDs), one of whom shall be an INED, shall have requisite knowledge and experience in innovative financial technology, Information Communication Technology (ICT) and/or cyber security.",
                isRequired: true,
                uniqueName: "R1Q7",
                entryName: "r1q7",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "In line with NCCG 2018, no Board of a bank shall consist of only one gender.",
                isRequired: true,
                uniqueName: "R1Q8",
                entryName: "r1q8",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "To achieve gender diversity and promote a gender inclusive board, banks shall take a practical approach to women’s economic empowerment in line with Principle 4 of the Nigerian Sustainable Banking Principles.",
                isRequired: true,
                uniqueName: "R1Q9",
                entryName: "r1q9",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Members of the Board shall be qualified persons of proven integrity and shall be knowledgeable in business and financial matters, in accordance with extant Guidelines on competency and fit and proper persons for the Nigerian banking industry.",
                isRequired: true,
                uniqueName: "R1Q10",
                entryName: "r1q10",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Track record of appointees to the Board shall be an additional eligibility requirement. Such records shall cover both integrity and past performance, in accordance with extant Guidelines on competency and fit and proper persons for the Nigerian banking industry.",
                isRequired: true,
                uniqueName: "R1Q11",
                entryName: "r1q11",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Not more than two members of an extended family shall be on the Board of a bank. <br /> The expression 'extended family' in this Guidelines includes director’s spouse, parents, children, siblings, cousins, uncles, aunts, nephews, nieces, in-laws and any other construed relationship as may be determined by the CBN.",
                isRequired: true,
                uniqueName: "R1Q12",
                entryName: "r1q12",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Only one member of an extended family can occupy the position of Managing Director/Chief Executive Officer (MD/CEO), Chairman or Executive Director (ED) at any point in time.",
                isRequired: true,
                uniqueName: "R1Q13",
                entryName: "r1q13",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Prospective and current directors on the Board of a bank are required to disclose potential and existing board memberships on boards of other organisations, as applicable, subject to CBN’s approval.",
                isRequired: true,
                uniqueName: "R1Q14",
                entryName: "r1q14",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "In the case of a bank that is a subsidiary of a Financial Holding Company (FHC), the aggregate number of directors from the subsidiaries shall not exceed thirty per cent (30%) of the members of the Board of the FHC and the number of directors on the Board of the FHC in the board of a subsidiary shall not exceed thirty per cent (30%).",
                isRequired: true,
                uniqueName: "R1Q15",
                entryName: "r1q15",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Interlocking or concurrent directorship by a director of a bank within its FHC or Group structure shall be limited to two institutions only.",
                isRequired: true,
                uniqueName: "R1Q16",
                entryName: "r1q16",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The position of an Executive Chairman or Vice Chairman shall not be recognised in the Board structure of any bank.",
                isRequired: true,
                uniqueName: "R1Q17",
                entryName: "r1q17",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "In the event a director elects to resign his appointment on the Board of a bank, such director shall submit a written notice of resignation addressed to the Chairman of the board, ninety (90) days before the effective date of resignation.",
                isRequired: true,
                uniqueName: "R1Q18",
                entryName: "r1q18",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "In addition to section 1.18 above, where an INED elects to resign, and such resignation would result in non-compliance with the minimum required number of INEDs, the Board shall within the ninety (90) days’ notice period appoint a replacement.",
                isRequired: true,
                uniqueName: "R1Q19",
                entryName: "r1q19",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Where a director elects to resign from the Board on account of unresolved concerns pertaining to the running of the bank, such director shall detail the concerns in a written statement to the Chairman for circulation to members of the Board. <br /> Such director shall within seven days of the notice of resignation forward to the CBN, a copy of the written statement.",
                isRequired: true,
                uniqueName: "R1Q20",
                entryName: "r1q20",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Where a NED resigns from the Board, and such resignation results in NEDs not being in the majority, the Board shall within the ninety (90) notice period appoint a replacement.",
                isRequired: true,
                uniqueName: "R1Q21",
                entryName: "r1q21",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "In the event that the Chairman of the Board elects to resign, the Chairman shall forward the notices specified in 1.18 and 1.20 (as may be applicable) to the Chairman, Board Nomination and Governance Committee (BNGC), who shall circulate to members of the Board and the CBN within seven days of receipt of the notice of resignation.",
                isRequired: true,
                uniqueName: "R1Q22",
                entryName: "r1q22",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "In the case of NIBs, a member of the Advisory Committee of Experts (ACE) and the Financial Regulation Advisory Council of Experts (FRACE) shall not be a member of the Board, senior management and/or staff of any Non- Interest Financial Institutions (NIFIs) under the regulatory purview of the CBN.",
                isRequired: true,
                uniqueName: "R1Q23",
                entryName: "r1q22",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Where a merger, acquisition, take-over, or any form of business combination involves the appointment of a director from the Board of the legacy institution, the length of service of such director shall include both the periods served pre and post combination.",
                isRequired: true,
                uniqueName: "R1Q24",
                entryName: "r1q24",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "2.0 ROLES AND RESPONSIBILITIES OF THE BOARD",
        description: "",
        questions: [
            {
                question:
                    "The Board is accountable and responsible for the performance and affairs of the bank. Specifically, and in line with the provisions in the CAMA 2020, directors owe the bank the duty of care and loyalty and shall act in the interest of its employees and other stakeholders.",
                isRequired: true,
                uniqueName: "R2Q1",
                entryName: "r2q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Members of the Board are jointly and severally liable for the activities of the bank.",
                isRequired: true,
                uniqueName: "R2Q2",
                entryName: "r2q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Board and its Committees shall each have a Charter to be approved by the CBN. These Charters shall be reviewed at least once every three years and upon any such review, the Board-approved copies shall be submitted to the CBN for its “No Objection” within thirty (30) days of approval by the Board and prior to its implementation.",
                isRequired: true,
                uniqueName: "R2Q3",
                entryName: "r2q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall define and approve the bank’s strategic goals, its short, medium and long-term strategies and monitor implementation by management.",
                isRequired: true,
                uniqueName: "R2Q4",
                entryName: "r2q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Board shall ensure a review of the investment policies and strategies of the bank at least once every three years, and submit same to the Director, Banking Supervision Department (BSD), CBN.",
                isRequired: true,
                uniqueName: "R2Q5",
                entryName: "r2q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Board shall have oversight of the bank’s Anti-Money Laundering/Combating the Financing of Terrorism and Countering Proliferation Financing (AML/CFT/CPF) policies and procedures.",
                isRequired: true,
                uniqueName: "R2Q6",
                entryName: "r2q6",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Board shall approve an Enterprise Risk Management (ERM) Framework specifying the bank’s risk appetite, risk culture, governance architecture, policies, procedures and processes for the identification, measurement, monitoring and control of the risks inherent in its operations.",
                isRequired: true,
                uniqueName: "R2Q7",
                entryName: "r2q7",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall ensure that there is a Business Continuity Plan (BCP) for the bank.",
                isRequired: true,
                uniqueName: "R2Q8",
                entryName: "r2q8",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Board shall develop and implement an Information Technology (IT) Framework that at a minimum covers data confidentiality, network security, third party connections, incidence response and reporting.",
                isRequired: true,
                uniqueName: "R2Q9",
                entryName: "r2q9",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Board shall determine the skills, knowledge and experience that its members require to work effectively as a team to achieve the bank’s objectives in line with extant Guidelines on competency and fit and proper persons for the Nigerian banking industry. <br /> Provided that where a director transmutes to a different role in the Board of the same bank, he/she shall meet the eligibility requirements of the new role as prescribed in extant Guidelines on competency and fit and proper persons for the Nigerian banking industry.",
                isRequired: true,
                uniqueName: "R2Q10",
                entryName: "r2q10",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall ensure that human, material and financial resources are effectively deployed towards the attainment of the goals of the bank.",
                isRequired: true,
                uniqueName: "R2Q11",
                entryName: "r2q11",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board, subject to CBN’s approval, shall appoint the MD/CEO, Executive Directors as well as senior management staff.",
                isRequired: true,
                uniqueName: "R2Q12",
                entryName: "r2q12",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall specifically designate one of the Executive Directors as the Executive Compliance Officer (ECO) and notify the CBN of such appointment.",
                isRequired: true,
                uniqueName: "R2Q13",
                entryName: "r2q13",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall approve a succession plan for the MD/CEO, other EDs and senior management staff, which shall be reviewed at least once every two years.",
                isRequired: true,
                uniqueName: "R2Q14",
                entryName: "r2q14",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall ensure the establishment and monitoring of agreed performance targets for the directors and senior management of the bank.",
                isRequired: true,
                uniqueName: "R2Q15",
                entryName: "r2q15",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall ensure that management implements an efficient internal control system.",
                isRequired: true,
                uniqueName: "R2Q16",
                entryName: "r2q16",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall establish a framework for the delegation of authority in the bank, and set and approve limits of authority, specifying the threshold for all transactions.",
                isRequired: true,
                uniqueName: "R2Q17",
                entryName: "r2q17",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall establish a structure to independently verify and safeguard the integrity of financial reporting.",
                isRequired: true,
                uniqueName: "R2Q18",
                entryName: "r2q18",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall establish a Code of Business Conduct and Ethics for all employees, setting out the bank’s values, standards, and ethical culture.",
                isRequired: true,
                uniqueName: "R2Q19",
                entryName: "r2q19",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall ensure strict adherence to the Code of Conduct for Directors of Banks and Other Financial Institutions in Nigeria issued by the CBN.",
                isRequired: true,
                uniqueName: "R2Q20",
                entryName: "r2q20",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall ensure that the bank practices good Corporate Social Responsibility (CSR).",
                isRequired: true,
                uniqueName: "R2Q21",
                entryName: "r2q21",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board of a CMNIB, on the recommendation of the Board Credit Committee (BCC) shall approve all insider credit applications, irrespective of the amount.",
                isRequired: true,
                uniqueName: "R2Q22",
                entryName: "r2q22",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "In addition to compliance with the sections above, the Board of a Non- Interest Bank (NIB) shall: Institutionalize a robust Shariah governance mechanism that is commensurate with the size and complexity of the NIB.",
                isRequired: true,
                uniqueName: "R2Q23(a)",
                entryName: "r2q23a",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "In addition to compliance with the sections above, the Board of a Non- Interest Bank (NIB) shall: subject to the approval of the CBN, appoint an ACE, in line with the provisions of the extant Guidelines for the Governance of the Advisory Committee of Experts in Nigeria.",
                isRequired: true,
                uniqueName: "R2Q23(b)",
                entryName: "r2q23b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "In addition to compliance with the sections above, the Board of a Non- Interest Bank (NIB) shall: oversee the implementation of the resolutions of the FRACE and the rulings of the NIB’s ACE.",
                isRequired: true,
                uniqueName: "R2Q23(c)",
                entryName: "r2q23c",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question:
                    "In addition to compliance with the sections above, the Board of a Non- Interest Bank (NIB) shall: ensure that strategic objectives, risk strategy, and corporate values of the bank, adhere to the principles underpinning the operations of non-interest banking.",
                isRequired: true,
                uniqueName: "R2Q23(d)",
                entryName: "r2q23d",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 4,
            },
            {
                question:
                    "In addition to compliance with the sections above, the Board of a Non- Interest Bank (NIB) shall: ensure that the funds of Profit-Sharing Investment Account Holders’ (PSIAHs’) are invested and managed in their best interest in line with the provisions of the extant Guidelines on the Management of Investment Account Holders for Non- Interest Financial Institutions in Nigeria.",
                isRequired: true,
                uniqueName: "R2Q23(e)",
                entryName: "r2q23e",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 5,
            },
            {
                question:
                    "In addition to compliance with the sections above, the Board of a Non- Interest Bank (NIB) shall: collaborate with the ACE to establish a policy on income smoothing towards returns paid to PSIAHs in line with the provisions of the extant Guidelines on the Practice of Smoothing, the Profit Pay-out to Investment Account Holders for Non-Interest Financial Institutions (NIFI) in Nigeria.",
                isRequired: true,
                uniqueName: "R2Q23(f)",
                entryName: "r2q23f",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 6,
            },
            {
                question:
                    "In addition to compliance with the sections above, the Board of a Non- Interest Bank (NIB) shall: ensure compliance with additional and complimentary disclosure requirements as specified in the extant Guidance Notes on Disclosure Requirements to Promote Transparency & Market Discipline for Non-Interest Financial Institutions in Nigeria.",
                isRequired: true,
                uniqueName: "R2Q23(g)",
                entryName: "r2q23g",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 7,
            },
            {
                question:
                    "In addition to compliance with the sections above, the Board of a Non- Interest Bank (NIB) shall: in collaboration with the ACE, establish policies for the determination and disposal to charity of Non-Permissible Income (NPI) in all its operations, subject to CBN approval.",
                isRequired: true,
                uniqueName: "R2Q23(h)",
                entryName: "r2q23h",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 8,
            },
            {
                question:
                    "In addition to compliance with the sections above, the Board of a Non- Interest Bank (NIB) shall: ensure that the nature and manner of the NIB’s CSR is in line with principles underpinning the operations of NIBs, provided that the activities related to the disposal of the bank’s NPI shall not be construed as part of its CSR.",
                isRequired: true,
                uniqueName: "R2Q23(i)",
                entryName: "r2q23i",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 9,
            },
            {
                question:
                    "In addition to compliance with the sections above, the Board of a Non- Interest Bank (NIB) shall: ensure that the ACE is free from any undue influence, is independent of the day-to-day management of the bank, and has direct access to the Board, senior management and officers in key control functions.",
                isRequired: true,
                uniqueName: "R2Q23(j)",
                entryName: "r2q23j",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 10,
            },
        ],
    },
    {
        name: "",
        title: "3.0 OFFICERS OF THE BOARD",
        description: "",
        questions: [
            {
                question:
                    "The qualifications and experience of the Chairman of the Board of a bank shall be as stated in extant Guidelines on competency and fit and proper persons for the Nigerian banking sector.",
                isRequired: true,
                uniqueName: "R3Q1(a)",
                entryName: "r3q1a",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "The Chairman shall meet formally with the NEDs at least once every year.",
                isRequired: true,
                uniqueName: "R3Q1(b)",
                entryName: "r3q1b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question: "Where a bank is a member of a Financial Holding Company (FHC), the Chairman of the bank shall not sit on the Board of the FHC in any capacity and vice versa.",
                isRequired: true,
                uniqueName: "R3Q1(c)",
                entryName: "r3q1c",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question: "The tenure of the MD/CEO of a bank shall be in accordance with the terms of engagement with the bank but subject to a maximum period of twelve (12) years.",
                isRequired: true,
                uniqueName: "R3Q2",
                entryName: "r3q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The tenure of a DMD/ED of a bank shall be in accordance with the terms of engagement with the bank but subject to a maximum period of twelve (12) years.",
                isRequired: true,
                uniqueName: "R3Q3(a)",
                entryName: "r3q3a",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "Where an ED becomes a DMD, a cumulative tenure of twelve (12) years applies and shall not be extended",
                isRequired: true,
                uniqueName: "R3Q3(b)",
                entryName: "r3q3b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "Where a DMD/ED becomes an MD/CEO of the same bank, his/her previous tenure as DMD/ED is not included in computing his/her tenure as MD/CEO. However, this is subject to a cumulative tenure limit as stated in Section 8 of this Guidelines.",
                isRequired: true,
                uniqueName: "R3Q3(c)",
                entryName: "r3q3c",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question:
                    "NEDs shall have unfettered access to corporate information from the MD/CEO, DMD, EDs, Company Secretary, Internal Auditor and Heads of other control functions with direct/indirect reporting lines to the Board, while access to other senior management shall be through the MD/CEO.",
                isRequired: true,
                uniqueName: "R3Q4(a)",
                entryName: "r3q4a",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "NEDS (with the exception of INEDs) of a bank shall serve for a maximum of twelve (12) years comprising three terms of four years each.",
                isRequired: true,
                uniqueName: "R3Q4(b)",
                entryName: "r3q4b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "To qualify as a NED in a bank, the proposed NED shall not be an employee of a financial institution except where the bank is promoted by that financial institution and the proposed NED is representing the interest of that financial institution.",
                isRequired: true,
                uniqueName: "R3Q4(c)",
                entryName: "r3q4c",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question:
                    "In the case of a commercial bank with an NIB window, at least one NED shall be knowledgeable and/or have experience in the field of Islamic finance or Islamic Commercial Jurisprudence.",
                isRequired: true,
                uniqueName: "R3Q4(d)",
                entryName: "r3q4d",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 4,
            },
            {
                question:
                    "An INED shall have sound knowledge of the operations, relevant laws and regulations guiding the banking sector. The INED shall also have proven skills and competencies in his/her field.",
                isRequired: true,
                uniqueName: "R3Q5(a)",
                entryName: "r3q5a",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "The tenure for INEDs shall not exceed two terms of four years each.",
                isRequired: true,
                uniqueName: "R3Q5(b)",
                entryName: "r3q5b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "In addition to the requirements of Recommended Practice 7 of NCCG 2018, an INED on the Board of a bank shall not: <br /> i. be a former director or employee who has served in the bank at a senior management level. <br /> ii. be a former employee below senior management level, within the last five years; <br /> iii. have any immediate family member as a current employee in senior management position; <br /> iv. have an immediate family member as a former employee of the bank who has served at senior management level in the preceding five years; <br /> v. have material relationship with the bank or any of its officers, ACE members (in the case of an NIB), major shareholders, subsidiaries and affiliates; a relationship which may impair the director’s ability to make independent judgments or compromise the director’s objectivity in line with corporate governance best practices; <br /> vi. provide financial, legal or consulting services to the bank or its subsidiaries/affiliates or has done so in the past five years; <br /> vii. borrow funds from the bank, its officers, subsidiaries and affiliates; <br /> viii. be part of management, executive committee or board of trustees of an institution, charitable or otherwise, supported by the bank; and <br /> ix. have served previously on the Board of its FHC, subsidiary or related entity within the banking group.",
                isRequired: true,
                uniqueName: "R3Q5(c)",
                entryName: "r3q5c",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question:
                    "It shall be the responsibility of an INED to notify the Board of any circumstance, event, transaction or relationship, which may impair the INED’s continued independence, as soon as such occurs.",
                isRequired: true,
                uniqueName: "R3Q5(d)",
                entryName: "r3q5d",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 4,
            },
            {
                question:
                    "The Board shall annually ascertain and confirm the continued independence of each INED. Where the assessment reveals that the independence of an INED has been impaired, such INED shall vacate his seat on the Board.",
                isRequired: true,
                uniqueName: "R3Q5(e)",
                entryName: "r3q5e",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 5,
            },
            {
                question:
                    "The transmutation of an ED or NED to an INED in the same bank or its group structure is not allowed. Similarly, An INED shall not transmute into any other capacity in the same bank or its group structure.",
                isRequired: true,
                uniqueName: "R3Q5(f)",
                entryName: "r3q5f",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 6,
            },
            {
                question: "All INEDs shall hold a formal meeting at least once in a year without the other directors being present.",
                isRequired: true,
                uniqueName: "R3Q5(g)",
                entryName: "r3q5g",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 7,
            },
            {
                question: "In the case of an NIB: At least one INED shall be knowledgeable and/or have experience in the field of Islamic finance or Islamic Commercial Jurisprudence.",
                isRequired: true,
                uniqueName: "R3Q5(h)(i)",
                entryName: "r3q5hi",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 8,
            },
            {
                question: "In the case of an NIB: No person who has served as a member of the ACE in any bank shall transmute into an INED of the same bank",
                isRequired: true,
                uniqueName: "R3Q5(h)(ii)",
                entryName: "r3q5hii",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 8,
            },
            {
                question:
                    "The qualifications and experience of a Company Secretary of a bank shall be in accordance with the extant Guidelines on competency and fit and proper persons in the Nigerian banking sector.",
                isRequired: true,
                uniqueName: "R3Q6(a)",
                entryName: "r3q6a",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "The functions of a company secretary shall not be outsourced by banks.",
                isRequired: true,
                uniqueName: "R3Q6(b)",
                entryName: "r3q6b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "The role of the Company Secretary in a CMNIB, shall not be combined with that of the Head Legal/Legal Adviser, without the approval of the CBN. <br /> <br /> <br /> In the case of PSBs, the function of the Company Secretary may be combined with that of the Head Legal/Legal Adviser.",
                isRequired: true,
                uniqueName: "R3Q6(c)",
                entryName: "r3q6c",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question: "The Company Secretary shall ensure that all board-related compliance matters are made available to the Executive Compliance Officer (ECO) in a timely manner.",
                isRequired: true,
                uniqueName: "R3Q6(d)",
                entryName: "r3q6d",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 4,
            },
            {
                question: "The appointment and removal of the Company Secretary shall be a matter for the Board, subject to CBN’s ratification.",
                isRequired: true,
                uniqueName: "R3Q6(e)",
                entryName: "r3q6e",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 5,
            },
            {
                question: "The Company Secretary shall report directly to the Board and have an indirect reporting line to the MD/CEO.",
                isRequired: true,
                uniqueName: "R3Q6(f)",
                entryName: "r3q6f",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 6,
            },
        ],
    },
    {
        name: "",
        title: "4.0 ACCESS TO INDEPENDENT PROFESSIONAL ADVICE",
        desription: "",
        questions: [
            {
                question: "The bank shall facilitate access to relevant independent professional advice for its directors and/or Board Committees.",
                isRequired: true,
                uniqueName: "R4Q1",
                entryName: "r4q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Requests for independent professional advice by directors and/or Board Committees shall be a matter for Board consideration and approval. The Board shall keep proper records of its decisions on such requests.",
                isRequired: true,
                uniqueName: "R4Q2",
                entryName: "r4q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall also keep detailed records of the professional advice provided to the concerned director(s) where the request is granted.",
                isRequired: true,
                uniqueName: "R4Q3",
                entryName: "r4q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "5.0 MEETINGS OF THE BOARD AND ITS COMMITTEES",
        description: "",
        questions: [
            {
                question: "The schedule of meetings of the Board and its Committees shall be approved by the Board ahead of each financial year.",
                isRequired: true,
                uniqueName: "R5Q1",
                entryName: "r5q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "To effectively perform its oversight function and monitor management’s performance, the Board and its Committees shall meet at least once every quarter. <br /> Provided that where the Remuneration Committee is a stand-alone committee, it should meet on need basis, but at least once a year.",
                isRequired: true,
                uniqueName: "R5Q2",
                entryName: "r5q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "In the case of an NIB, the Board shall meet formally with the ACE at least once every quarter while for a commercial bank with an NIB window, the Board shall meet formally with the ACE at least twice in a year.",
                isRequired: true,
                uniqueName: "R5Q3",
                entryName: "r5q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The meeting of the Board and its Committees shall be held at a specified location or virtually if physical meetings cannot be held.",
                isRequired: true,
                uniqueName: "R5Q4",
                entryName: "r5q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The quorum for the meetings of the Board and its committees shall be two- thirds of members, majority of whom shall be NEDs.",
                isRequired: true,
                uniqueName: "R5Q5",
                entryName: "r5q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Every Director is required to attend all meetings of the Board and its Committees that he or she is a member. In order to qualify for reappointment, a Director must have attended at least two-thirds of all Board and its Committee meetings.",
                isRequired: true,
                uniqueName: "R5Q6",
                entryName: "r5q6",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Minutes of meetings of the Board and its Committees shall be properly written in English language, adopted by members and signed off by the Board/Board Committee Chairman and Company Secretary, pasted in the minutes book and domiciled at the bank's Head Office.",
                isRequired: true,
                uniqueName: "R5Q7",
                entryName: "r5q7",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "6.0 BOARD COMMITTEES",
        description: "",
        questions: [
            {
                question: "The terms of reference and composition of Board Committees shall be set out in the board-approved charter for each committee.",
                isRequired: true,
                uniqueName: "R6Q1",
                entryName: "r6q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The membership of Board committees shall be reviewed and refreshed at least once every three years.",
                isRequired: true,
                uniqueName: "R6Q2",
                entryName: "r6q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "All Board committees shall be chaired by NEDs. However, the Board Audit Committee (BAC), Board Nomination and Governance Committee (BNGC) and the Board Remuneration Committee (BRC) shall be chaired by INEDs.",
                isRequired: true,
                uniqueName: "R6Q3",
                entryName: "r6q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Chairman of the BNGC of an NIB shall be knowledgeable and experienced in Islamic Finance or Islamic Commercial Jurisprudence.",
                isRequired: true,
                uniqueName: "R6Q4",
                entryName: "r6q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "In addition to the mandatory Committees listed in Recommended Practice 11.1.6 of NCCG 2018, the Board of any CMNIB shall also establish a Board Credit Committee (BCC) with oversight responsibility on credit matters.",
                isRequired: true,
                uniqueName: "R6Q5",
                entryName: "r6q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "In the case of a PSB, the Board shall in addition to the mandatory Committees listed in Recommended Practice 11.1.6 of NCCG 2018, establish a Board Committee responsible for Information & Communication Technology (ICT) and Cybersecurity.",
                isRequired: true,
                uniqueName: "R6Q6",
                entryName: "r6q6",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Banks shall maintain the exact names of each of the mandatory Committees stated in Recommended Practice 11.1.6 of NCCG 2018 and in this Guidelines, and where the functions of two or more Committees are combined in a single Committee as approved by CBN, the name of such committee shall reflect the roles or functions combined.",
                isRequired: true,
                uniqueName: "R6Q7",
                entryName: "r6q7",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Chairman of the Board shall not be in attendance by invitation or otherwise in any of the Board Committee meetings",
                isRequired: true,
                uniqueName: "R6Q8",
                entryName: "r6q8",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The MD/CEO, DMD and EDs shall not be in attendance either by invitation or otherwise, at any meeting of the Board or its Committees, where the remuneration of EDs will be discussed.",
                isRequired: true,
                uniqueName: "R6Q9",
                entryName: "r6q9",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The functions of the Board Risk Management Committee (BRMC) and the Board Audit Committee (BAC) shall not be combined for CMNIBs. <br /> Provided however, that at least one NED on the Board of the bank shall be a member of both Committees concurrently.",
                isRequired: true,
                uniqueName: "R6Q10",
                entryName: "r6q10",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Members of each of the Board Committees shall appoint one of its members as Chairman.",
                isRequired: true,
                uniqueName: "R6Q11",
                entryName: "r6q11",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The establishment of sub-committees of Board committees is prohibited.",
                isRequired: true,
                uniqueName: "R6Q12",
                entryName: "r6q12",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board Audit Committee shall consist of NEDs only.",
                isRequired: true,
                uniqueName: "R6Q13(a)",
                entryName: "r6q13a",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "All members of the Board Audit Committee of a bank shall be able to read and understand financial statements. At least one member of the BAC shall have relevant professional qualifications and experience in financial and accounting matters.",
                isRequired: true,
                uniqueName: "R6Q13(b)",
                entryName: "r6q13b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "At least one member of the Board Audit Committee of a CMNIB shall be knowledgeable in innovative technology, ICT and/or cybersecurity. In the case of a PSB, majority of the BAC shall be knowledgeable in innovative technology, ICT and/or cybersecurity.",
                isRequired: true,
                uniqueName: "R6Q13(c)",
                entryName: "r6q13c",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question:
                    "In the case of the Board Audit Committee for an NIB, at least one member shall be an INED, who shall possess relevant qualification, knowledge and experience in Islamic finance or Islamic commercial jurisprudence.",
                isRequired: true,
                uniqueName: "R6Q13(d)",
                entryName: "r6q13d",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 4,
            },
            {
                question: "The Board Audit Committee shall have unrestricted access to the financial records of the bank including the External Auditors’ reports.",
                isRequired: true,
                uniqueName: "R6Q13(e)",
                entryName: "r6q13e",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 5,
            },
            {
                question: "The Board of a bank shall not replace members of the Board Audit Committee and External Auditors at the same time.",
                isRequired: true,
                uniqueName: "R6Q13(f)",
                entryName: "r6q13f",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 6,
            },
            {
                question: "The Board Audit Committee shall review the integrity of the bank’s financial reporting and ensure the independence of the External Auditors.",
                isRequired: true,
                uniqueName: "R6Q13(g)",
                entryName: "r6q13g",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 7,
            },
            {
                question: "The BRMC of a bank shall be chaired by a NED and its composition shall include at least two NEDs and the ED in charge of risk management.",
                isRequired: true,
                uniqueName: "R6Q14(a)",
                entryName: "r6q14a",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "In the case of an NIB: <br /> (i) The BRMC shall be chaired by a NED and at least one of the NEDs shall have relevant qualification and experience in Islamic finance or Islamic Commercial Jurisprudence. <br / (ii) In the case of a commercial bank with an NIB window, at least one NED in the BRMC shall have relevant qualification and experience in Islamic finance or Islamic Commercial Jurisprudence.",
                isRequired: true,
                uniqueName: "R6Q14(b)",
                entryName: "r6q14b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "The Head of the NIB window of a commercial bank shall be a senior management staff with knowledge and experience in the field of Islamic finance or Islamic Commercial Jurisprudence.",
                isRequired: true,
                uniqueName: "R6Q15",
                entryName: "r6q15",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "7.0 COOL-OFF PERIOD",
        description: "",
        questions: [
            {
                question:
                    "An Executive (ED, DMD or MD/CEO) who exits from the Board of a bank either upon or prior to the expiration of his/her maximum tenure, shall serve out a cooling period of two (2) years before being eligible for appointment as a NED in the same bank, subject to applicable cumulative tenure limits.",
                isRequired: true,
                uniqueName: "R7Q1",
                entryName: "r7q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Where an Executive (ED, DMD or MD/CEO) of a bank is appointed to the Board of its FHC in any role, a cooling-off period of two years shall apply.",
                isRequired: true,
                uniqueName: "R7Q2",
                entryName: "r7q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Transmutation of an INED or an ACE (in the case of an NIB) into any role in the same bank is not permitted.",
                isRequired: true,
                uniqueName: "R7Q3",
                entryName: "r7q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "A NED shall serve out a cooling period of two (2) years before being eligible for appointment in any executive role in the same bank.",
                isRequired: true,
                uniqueName: "R7Q4",
                entryName: "r7q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "No cooling-off period shall apply when any director in a bank is appointed to the Board of another bank or an FHC outside the bank’s group.",
                isRequired: true,
                uniqueName: "R7Q5",
                entryName: "r7q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Cooling-off period of two (2) years shall apply, where a director from a bank transition to a sister subsidiary and it results in a change of role. However, cooling-off period shall not apply where there is no change of role.",
                isRequired: true,
                uniqueName: "R7Q6",
                entryName: "r7q6",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "A member of FRACE shall not take up appointment as a director or a member of an ACE of any NIFI under the supervisory purview of the CBN, until after a cool-off period of three years.",
                isRequired: true,
                uniqueName: "R7Q7",
                entryName: "r7q7",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The tenure of auditors in a bank shall be a maximum period of ten (10) consecutive years, subject to the rotation of audit engagement partner at least once every five years. For an audit firm to be reappointed by the same bank, a cooling-off period of ten (10) consecutive years shall be observed.",
                isRequired: true,
                uniqueName: "R7Q8",
                entryName: "r7q8",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Subject to the approval of the CBN, there shall be a cooling-off period of three (3) years between the retirement of a partner from an audit firm currently auditing a bank and the appointment of such partner to the Board of the same bank.",
                isRequired: true,
                uniqueName: "R7Q9",
                entryName: "r7q9",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "A cool-off period of three years shall be observed prior to a: <br /> (a) member of an audit team who participated in auditing a bank being employed by the same bank. <br / (b) staff of a bank being engaged by an audit firm to join the team to audit the same bank.",
                isRequired: true,
                uniqueName: "R7Q10",
                entryName: "r7q10",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "An audit firm shall not provide audit services to a bank if one of the bank’s senior/top management was employed by the firm and worked on the bank’s audit during the immediate past three (3) years.",
                isRequired: true,
                uniqueName: "R7Q11",
                entryName: "r7q11",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Governor and Deputy Governors of the CBN, the MD/CEO and Executive Directors of the Nigeria Deposit Insurance Corporation (NDIC) and the departmental directors of the CBN and the NDIC, shall not be eligible for appointment in any capacity in a bank, until after a cooling-off period as may prescribed by the Board of the CBN or NDIC (as applicable).",
                isRequired: true,
                uniqueName: "R7Q12",
                entryName: "r7q12",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "8.0 CUMMULATIVE TENURE",
        description: "",
        questions: [
            {
                question: "The cumulative tenure limit of directors (ED, DMD, MD and NEDs) on the Board of the same bank is twenty-four (24) years.",
                isRequired: true,
                uniqueName: "R8Q1",
                entryName: "r8q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The cumulative period is calculated from the date of first appointment to the Board of the bank.",
                isRequired: true,
                uniqueName: "R8Q2",
                entryName: "r8q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "9.0 INDUCTION AND CONTINUING EDUCATION",
        description: "",
        questions: [
            {
                question:
                    "A formal induction programme for new directors shall be conducted within three months of their appointment. The details of such training shall be availed to examiners upon request.",
                isRequired: true,
                uniqueName: "R9Q1",
                entryName: "r9q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall approve an annual budget for the training and continuing education for directors and ensure its proper implementation.",
                isRequired: true,
                uniqueName: "R9Q2",
                entryName: "r9q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "10.0 BOARD EVALUATION",
        description: "",
        questions: [
            {
                question:
                    "There shall be an annual appraisal of the Board, its Committees, Chairman and individual directors covering all aspects of the Board’s structure, composition, responsibilities, processes and relationships, as may be prescribed by the CBN from time to time.",
                isRequired: true,
                uniqueName: "R10Q1",
                entryName: "r10q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "In the case of NIBs, there shall be an annual appraisal of the ACE covering all aspects of its responsibilities, processes, meetings and overall functions",
                isRequired: true,
                uniqueName: "R10Q2",
                entryName: "r10q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The appraisals in Sections 10.1 and 10.2 shall be conducted by an independent external consultant with adequate experience, knowledge and competence in corporate governance and performance management. Additionally, for NIBs, such independent consultant shall also possess knowledge and experience in Islamic Finance or Islamic Commercial Jurisprudence.",
                isRequired: true,
                uniqueName: "R10Q3",
                entryName: "r10q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Board Evaluation exercise shall, at a minimum, cover the scope described in the extant CBN Guidelines for Annual Board Evaluation by External Consultants of Banks and Other Financial Institutions in Nigeria.",
                isRequired: true,
                uniqueName: "R10Q4",
                entryName: "r10q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Banks shall forward to the Director, Financial Policy and Regulation Department (FPRD), CBN, the report of the annual evaluation of the Board and ACE by the independent external consultant latest by May 31st following the end of every financial year or before the Annual General Meeting at which the report for the period/year is to be considered, whichever comes first.",
                isRequired: true,
                uniqueName: "R10Q5",
                entryName: "r10q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The continuous unsatisfactory performance by a director shall be a basis for non-renewal of such a director’s tenure.",
                isRequired: true,
                uniqueName: "R10Q6",
                entryName: "r10q6",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "11.0 REMUNERATION",
        description: "",
        questions: [
            {
                question: "The Board shall develop a remuneration policy, which shall be disclosed in the annual report.",
                isRequired: true,
                uniqueName: "R11Q1",
                entryName: "r11q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Banks shall align executive and Board remuneration to its long-term interests and that of its shareholders.",
                isRequired: true,
                uniqueName: "R11Q2",
                entryName: "r11q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Remuneration by banks shall be sufficient to attract, retain and motivate staff. This shall be balanced against the bank’s interest to avoid paying excessive remuneration.",
                isRequired: true,
                uniqueName: "R11Q3",
                entryName: "r11q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Board shall approve the remuneration of MD/CEO, DMD, EDs, Senior Management and other employees, while the fees and allowances for the NEDs shall be fixed by the Board and approved by shareholders at a General Meeting.",
                isRequired: true,
                uniqueName: "R11Q4",
                entryName: "r11q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "NEDs remuneration shall be limited to Director’s fees, sitting allowances for Board and its Committee meetings and reimbursable travel and hotel expenses. NEDs shall not receive benefits, salaries or any other allowances whether in cash or in kind other than those mentioned above.",
                isRequired: true,
                uniqueName: "R11Q5",
                entryName: "r11q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Remuneration of MD/CEO, DMD, and EDs shall be linked to performance and structured to prevent excessive risk taking.",
                isRequired: true,
                uniqueName: "R11Q6",
                entryName: "r11q6",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Where stock options are adopted as part of executive remuneration or compensation, the Board shall ensure that they are not priced at a discount.",
                isRequired: true,
                uniqueName: "R11Q7",
                entryName: "r11q7",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Share options shall be tied to performance and subject to the approval of the shareholders at an AGM.",
                isRequired: true,
                uniqueName: "R11Q8",
                entryName: "r11q8",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Share options shall not be exercisable until one year after the expiration of the tenure of the director.",
                isRequired: true,
                uniqueName: "R11Q9",
                entryName: "r11q9",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall at the end of each financial year, confirm that the implementation and execution of the remuneration policy achieved its objectives.",
                isRequired: true,
                uniqueName: "R11Q10",
                entryName: "r11q10",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "12.0 RISK MANAGEMENT FUNCTION",
        description: "",
        questions: [
            {
                question:
                    "The approved Enterprise Risk Management (ERM) Framework of a bank shall clearly describe the roles and responsibilities of the Board, BRMC, ED Risk, Chief Risk Officer (CRO), senior management and internal control function.",
                isRequired: true,
                uniqueName: "R12Q1",
                entryName: "r12q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The qualification and experience of the head of the risk management function shall be in accordance with the extant Guidelines on competency and fit and proper persons for the Nigerian banking sector.",
                isRequired: true,
                uniqueName: "R12Q2",
                entryName: "r12q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Board shall ensure that the risk management function is headed by an ED. In the case of NIBs with regional authorization and PSBs, the risk management function shall be headed by a senior management officer with relevant qualification, competence and experience.",
                isRequired: true,
                uniqueName: "R12Q3",
                entryName: "r12q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The CRO, who shall not be below the grade of an Assistant General Manager, shall report to the ED Risk who reports to the Board.",
                isRequired: true,
                uniqueName: "R12Q4",
                entryName: "r12q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall review the effectiveness of the implementation of risk management policies and procedures at least annually.",
                isRequired: true,
                uniqueName: "R12Q5",
                entryName: "r12q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall review the ERM Framework at least once in three years.",
                isRequired: true,
                uniqueName: "R12Q6",
                entryName: "r12q6",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Banks shall disclose a summary of their risk management policies in their annual financial statements. In the case of a publicly quoted bank, such summary shall be hosted on its website.",
                isRequired: true,
                uniqueName: "R12Q7",
                entryName: "r12q7",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "13.0 INTERNAL AUDIT FUNCTION",
        description: "",
        questions: [
            {
                question: "A bank shall not outsource its internal audit/compliance functions.",
                isRequired: true,
                uniqueName: "R13Q1",
                entryName: "r13q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The qualification and experience of the head of internal audit shall be in accordance with the provisions of the extant Guidelines on competency and fit and proper persons for the Nigerian banking sector.",
                isRequired: true,
                uniqueName: "R13Q2",
                entryName: "r13q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The appointment and removal of the head of internal audit shall be the responsibility of the Board, subject to CBN’s approval.",
                isRequired: true,
                uniqueName: "R13Q3",
                entryName: "r13q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The head of internal audit, who shall not be below the rank of an Assistant General Manager, shall report directly to the BAC.",
                isRequired: true,
                uniqueName: "R13Q4",
                entryName: "r13q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "An independent external assessment of the effectiveness of the internal audit function as provided in Recommended Practice18.6 of NCCG 2018 shall be carried out annually and the report submitted to the Director, Banking Supervision Department, latest May 31st following the end of every accounting year.",
                isRequired: true,
                uniqueName: "R13Q5",
                entryName: "r13q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "14.0 INTERNAL SHARIAH AUDIT",
        description: "",
        questions: [
            {
                question:
                    "NIBs shall have an internal shariah audit function headed by an Internal Shariah Auditor (ISA) not below the rank of an Assistant General Manager. In the case of commercial banks with NIB window, the head of the internal shariah audit function shall not be below the rank of a Manager.",
                isRequired: true,
                uniqueName: "R14Q1",
                entryName: "r14q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The head of the internal shariah audit function shall provide an independent assessment on the quality and effectiveness of the NIB’s internal control, risk management systems and governance processes as well as the overall compliance of the NIB’s operations with the principles of non-interest banking.",
                isRequired: true,
                uniqueName: "R14Q2",
                entryName: "r14q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The ISA in consultation with the ACE, shall determine the scope of the shariah audit and is required to produce internal shariah compliant reports which shall be submitted quarterly to the ACE and the BAC.",
                isRequired: true,
                uniqueName: "R14Q3",
                entryName: "r14q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The ISA shall: <br /> a. be responsible for determining NPI (if any) and shall ensure its disbursement to charity under the supervision of the bank’s ACE. <br /> b. ensure that a quarterly report on the disposal of the NPI, is duly endorsed by the ACE, and forwarded to the Director, BSD, not later than seven days after the end of each quarter. <br /> c. report directly to the BAC, and indirectly to the ACE.",
                isRequired: true,
                uniqueName: "R14Q4",
                entryName: "r14q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Appointment and removal of the ISA shall be the responsibility of the Board in consultation with the ACE, subject to CBN’s ratification.",
                isRequired: true,
                uniqueName: "R14Q5",
                entryName: "r14q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "15.0 COMPLIANCE FUNCTION",
        description: "",
        questions: [
            {
                question: "The Executive Compliance Officer (ECO) shall not combine his/her responsibility with income generating activity.",
                isRequired: true,
                uniqueName: "R15Q1",
                entryName: "r15q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The ECO shall primarily be responsible for: <br />a. cascading regulatory requirements and expectations (including accountability and responsibility) along control and operational functions such as audit, risk management finance, foreign exchange transactions, AML/CFT/CPF, IT and cyber-security etc. <br />b. presenting to the Board all regulatory infractions and concerns.",
                isRequired: true,
                uniqueName: "R15Q2",
                entryName: "r15q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Banks shall have a Chief Compliance Officer (CCO) who shall not be below the rank of a General Manager for commercial and non-interest banks with national and international authorization, an Assistant General Manager for merchant banks and commercial and non-interest banks with regional authorization.",
                isRequired: true,
                uniqueName: "R15Q3",
                entryName: "r15q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The CCO shall have the primary responsibility of monitoring and coordinating the implementation of regulatory requirements as cascaded by the ECO.",
                isRequired: true,
                uniqueName: "R15Q4",
                entryName: "r15q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The qualification and experience of the CCO shall be in accordance with the provisions of the extant Guidelines on competency and fit and proper persons for the Nigerian banking industry.",
                isRequired: true,
                uniqueName: "R15Q5",
                entryName: "r15q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The appointment and removal of the CCO shall be the responsibility of the Board subject to CBN’s approval.",
                isRequired: true,
                uniqueName: "R15Q6",
                entryName: "r15q6",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The CCO shall report to the Board through the ECO.",
                isRequired: true,
                uniqueName: "R15Q7",
                entryName: "r15q7",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "16.0 SHARI’AH COMPLIANCE FUNCTION",
        description: "",
        questions: [
            {
                question:
                    "NIBs shall establish a Shariah Review/Compliance (SRC) function that conducts regular assessment of the compliance of the NIB’s operations and activities in line with shariah requirements.",
                isRequired: true,
                uniqueName: "R16Q1",
                entryName: "r16q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Internal Shariah Compliance Officer (ISCO) shall: <br /> at a minimum, identify, measure, monitor and report on Shariah Non-Compliance Risks (SNCR) in the operations of the NIBs on a daily basis.",
                isRequired: true,
                uniqueName: "R16Q2(a)",
                entryName: "r16q2a",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "The Internal Shariah Compliance Officer (ISCO) shall: <br/> be responsible for reviewing all financing requests before disbursement to avoid SNCR.",
                isRequired: true,
                uniqueName: "R16Q2(b)",
                entryName: "r16q2b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question: "The Internal Shariah Compliance Officer (ISCO) shall: <br /> report directly to the CCO and indirectly to the ACE.",
                isRequired: true,
                uniqueName: "R16Q2(c)",
                entryName: "r16q2c",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question: "The Internal Shariah Compliance Officer (ISCO) shall: <br /> not be below the rank of a Manager or a lower rank in the case of a commercial bank with an NIB window. ",
                isRequired: true,
                uniqueName: "R16Q2(d)",
                entryName: "r16q2d",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 4,
            },
            {
                question: "The appointment and removal of the ISCO shall be the responsibility of the Board in consultation with the ACE, subject to CBN’s ratification.",
                isRequired: true,
                uniqueName: "R16Q3",
                entryName: "r16q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "17.0 WHISTLE-BLOWING",
        description: "",
        questions: [
            {
                question:
                    "Banks shall comply with Recommended Practice 19 of NCCG 2018, as well as the provisions of the extant CBN Guidelines for Whistleblowing for Banks and Other Financial Institutions in Nigeria.",
                isRequired: true,
                uniqueName: "R17Q1",
                entryName: "r17q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "18.0 EXTERNAL AUDITORS",
        description: "",
        questions: [
            {
                question: "The appointment and removal of the external auditor shall be the responsibility of the Board, subject to CBN’s approval.",
                isRequired: true,
                uniqueName: "R18Q1",
                entryName: "r18q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The external auditor shall report annually in the financial statements, the extent of the bank’s compliance with the provisions of NCCG 2018 and this Guidelines.",
                isRequired: true,
                uniqueName: "R18Q2",
                entryName: "r18q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The external auditor shall render annual reports to the Director, BSD on the bank’s risk management practices, internal controls and level of compliance with regulatory directives.",
                isRequired: true,
                uniqueName: "R18Q3",
                entryName: "r18q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The report stated in Section 18.3 shall, in the case of an NIB, include an assessment of the process of identification and disposal of NPI, the treatment of PSIAHs and income smoothing (if any).",
                isRequired: true,
                uniqueName: "R18Q4",
                entryName: "r18q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "In addition to the requirements in this Section, the external auditor of an NIB shall review the: <br />	i. compliance of the bank with the decisions of the ACE and FRACE; <br />	ii. work of the ISA and the ISCO.",
                isRequired: true,
                uniqueName: "R18Q5",
                entryName: "r18q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The external auditor shall forward copies of the report together with its management letter on the bank’s audited financial statements, to the Director, Banking Supervision Department, latest March 31st following the end of every accounting year.",
                isRequired: true,
                uniqueName: "R18Q6",
                entryName: "r18q6",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Banks are required to publish their annual audited financial statements in two (2) national daily newspapers and on their websites.",
                isRequired: true,
                uniqueName: "R18Q7",
                entryName: "r18q7",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "External auditor of banks shall not provide client services that could amount to conflict of interest, including the following: <br /> 	i. Bookkeeping or other services related to the accounting records or financial statements of the audit client; <br />	ii. Valuation services, fairness opinion or contribution-in-kind reports; <br />	iii. Actuarial services; <br />	iv. Internal audit outsourcing services; <br />	v. Management or human resource functions including broker or dealer, investment banking services and legal or expert services; <br />	vi. Board evaluation and appraisal services; <br />	vii. IT and system audit; and <br />	viii. Software sales, consultancy, and management.",
                isRequired: true,
                uniqueName: "R18Q8",
                entryName: "r18q8",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Where the CBN is satisfied that an external auditor of a bank has engaged in any unethical practice or illegal activity, the CBN shall request the Board of the bank to remove the external auditors, or it may impose any other sanction on the bank in line with the provisions of extant laws and regulations.",
                isRequired: true,
                uniqueName: "R18Q9",
                entryName: "r18q9",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "19.0 GENERAL MEETINGS",
        description: "",
        questions: [
            {
                question: "The Board shall ensure that the venue of a general meeting is convenient and easily accessible to the majority of shareholders. ",
                isRequired: true,
                uniqueName: "R19Q1",
                entryName: "r19q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board may consider rotating the venue of general meetings where it will promote better access to the majority of shareholders.",
                isRequired: true,
                uniqueName: "R19Q2",
                entryName: "r19q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Banks may hold their general meetings virtually, where physical meetings are not feasible.",
                isRequired: true,
                uniqueName: "R19Q3",
                entryName: "r19q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "20.0 TREATMENT OF SHAREHOLDERS",
        description: "",
        questions: [
            {
                question: "The Board of a bank with institutional investors shall ensure that such investors carry out the responsibilities detailed in Recommended Practice 22.3 of NCCG 2018.",
                isRequired: true,
                uniqueName: "R20Q1(a)",
                entryName: "r20q1a",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "The Board shall ensure that dealings of publicly listed banks with shareholders’ associations are in strict adherence with the Code of Conduct for Shareholders’ Associations issued by the Securities and Exchange Commission (SEC).",
                isRequired: true,
                uniqueName: "R20Q1(b)",
                entryName: "r20q1b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question: "Where a bank is not publicly quoted, its dealings with shareholders shall be transparent and in line with best practices.",
                isRequired: true,
                uniqueName: "R20Q1(c)",
                entryName: "r20q1c",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question:
                    "Except where prior approval of the CBN is granted, no individual, group of individuals, their proxies or corporate entities shall own controlling interest in more than one bank.",
                isRequired: true,
                uniqueName: "R20Q2(a)",
                entryName: "r20q2a",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "CBN’s prior approval and No Objection shall be sought and obtained before any acquisition of shares of a bank (including through the capital market), that would result in equity holding of five per cent (5%) and above, by any investor.",
                isRequired: true,
                uniqueName: "R20Q2(b)",
                entryName: "r20q2b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "Where the CBN has an objection on any acquisition as stated in Section 20.2.b above, notice of the objection shall be communicated to the bank, and the bank shall notify such investor(s) within forty- eight (48) hours.",
                isRequired: true,
                uniqueName: "R20Q2(c)",
                entryName: "r20q2c",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question:
                    "Government’s direct and indirect equity holding in a bank shall not be more than ten per cent (10%), which shall be divested to private investors within a maximum period of five years from the date of investment. <br /> For existing investments above five years, the bank shall within two years from the effective date of this Guidelines, comply with the provision.",
                isRequired: true,
                uniqueName: "R20Q2(d)",
                entryName: "r20q2d",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 4,
            },
        ],
    },
    {
        name: "",
        title: "21.0 BUSINESS CONDUCT AND ETHICS",
        description: "",
        questions: [
            {
                question:
                    "Banks shall establish a Code of Business Conduct and Ethics and disclose in the code, such information and practices necessary to maintain confidence in the bank’s integrity.",
                isRequired: true,
                uniqueName: "R21Q1",
                entryName: "r21q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Code referred to in Section 21.1 shall take into account the legal obligations and reasonable expectations of the banks’ stakeholders, as well as the responsibility and accountability of individuals reporting on issues of unethical practices.",
                isRequired: true,
                uniqueName: "R21Q2",
                entryName: "r21q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Code shall be reviewed at least once every three years.",
                isRequired: true,
                uniqueName: "R21Q3",
                entryName: "r21q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "22.0 RELATED PARTY TRANSACTIONS",
        description: "",
        questions: [
            {
                question:
                    "Banks shall establish a policy concerning insider trading and related party transactions by directors, senior executives, and employees, as well as publish the policy or a summary of that policy on their website.",
                isRequired: true,
                uniqueName: "R22Q1",
                entryName: "r22q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The policy shall contain appropriate standards and procedures to ensure it is effectively implemented.",
                isRequired: true,
                uniqueName: "R22Q2",
                entryName: "r22q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "In addition to the requirements in Section 22.2, there shall be an internal review mechanism carried out by the internal audit function of the bank, to assess the compliance and effectiveness of the policy.",
                isRequired: true,
                uniqueName: "R22Q3",
                entryName: "r22q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Any director whose facility or that of his/her related interests remains non- performing in any financial institution for more than one year shall cease to be on the Board of the bank and shall be blacklisted from sitting on the Board of such bank and that of any other financial institution under the purview of the CBN.",
                isRequired: true,
                uniqueName: "R22Q4",
                entryName: "r22q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "No director-related loans and/or interest thereon shall be written off without the CBN’s prior approval.",
                isRequired: true,
                uniqueName: "R22Q5",
                entryName: "r22q5",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "In the case of a PSB: <br /> Where a PSB is a related company to an existing infrastructure provider which provides services to other financial institutions, the PSB shall ensure that its dealings with the infrastructure provider are at arms-length.",
                isRequired: true,
                uniqueName: "R22Q6(a)",
                entryName: "r22q6a",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "In the case of a PSB: <br />The following conditions shall guide business conduct between PSBs, their parent companies and other related entities (where applicable): <br/> A parent company or any other related entity of a PSB, which renders services to the PSB may extend similar services to other entities that so desire on the same terms and conditions.",
                isRequired: true,
                uniqueName: "R22Q6(b)(i)",
                entryName: "r22q6bi",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "In the case of a PSB: <br />The following conditions shall guide business conduct between PSBs, their parent companies and other related entities (where applicable): <br /> <br />A parent company or any other related entity of a PSB is prohibited from giving any preferential treatment to the PSB. <br /> <br />Preferential treatment by a parent company or any other related entity shall, among others, include <br/> i. Precluding its subsidiary’s competitor from using its infrastructure or services <br />ii. Offering lower quality of service to its subsidiary’s competitors <br /> iii. Offering such infrastructure or services at differential pricing.",
                isRequired: true,
                uniqueName: "R22Q6(b)(ii)",
                entryName: "r22q6bii",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "In the case of a PSB: <br /> All services between the parent company and its PSB shall be guided by Service Level Agreements (SLAs) and/or shared services arrangements in line with the CBN Guidelines for Shared Services Arrangements for Banks and Other Financial Institutions.",
                isRequired: true,
                uniqueName: "R22Q6d",
                entryName: "r22q6d",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 4,
            },
            {
                question: "In the case of a PSB: <br /> A PSB shall submit the SLAs referred to in Section 22.6(d) to the CBN for approval, prior to implementation.",
                isRequired: true,
                uniqueName: "R22Q6e",
                entryName: "r22q6e",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 5,
            },
        ],
    },
    {
        name: "",
        title: "23.0 CONFLICT OF INTEREST",
        description: "",
        questions: [
            {
                question:
                    "Banks shall develop and adopt a policy to guide the Board and individual directors in conflict-of-interest situations, which shall, at the minimum, cover the following areas: <br /> I. Approval & revision date; <br /> II. Definition of conflict of interest; <br /> III. Purpose of the policy; and <br /> IV. Procedures to follow in situations of conflict of interest.",
                isRequired: true,
                uniqueName: "R23Q1",
                entryName: "r23q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall be responsible for managing conflicts of interest of directors and senior management in a bank.",
                isRequired: true,
                uniqueName: "R23Q2",
                entryName: "r23q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Any concern raised by a director on the activities of his/her FHC and all discussions on conflict of interest shall be recorded in the minutes of the Board/Board Committee meetings.",
                isRequired: true,
                uniqueName: "R23Q3",
                entryName: "r23q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "24.0 SUSTAINABILITY",
        description: "",
        questions: [
            {
                question: "Banks shall comply with the provisions of Recommended Practice 26 of NCCG 2018 as well as the requirements of the Nigerian Sustainable Banking Principles.",
                isRequired: true,
                uniqueName: "R24Q1",
                entryName: "r24q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "25.0 STAKEHOLDER COMMUNICATION",
        description: "",
        questions: [
            {
                question:
                    "In addition to the traditional means of communication, banks shall have a website and are encouraged to communicate with stakeholders via the website and other official channels.",
                isRequired: true,
                uniqueName: "R25Q1",
                entryName: "r25q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall develop stakeholder communication policy host same on its website.",
                isRequired: true,
                uniqueName: "R25Q2",
                entryName: "r25q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Board shall ensure that stakeholders have the freedom to communicate their concerns on illegal or unethical practices to the Board. Where the concerns relate to the activities of the Board, such individuals may present a complaint to the CBN.",
                isRequired: true,
                uniqueName: "R25Q3",
                entryName: "r25q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "26.0 DISCLOSURES",
        description: "",
        questions: [
            {
                question:
                    "Disclosure in the annual report shall include, but not limited to, material information on: <br /> <br />	Directors, including: <br />	i. Remuneration policy for members of the Board and executives; <br />	ii. Total remuneration of NEDs (fees and allowances); <br />	iii. Total Executive compensation, including bonuses paid/payable; <br />	iv. Details and reasons for share buy-backs, if any, during the period under review; <br />	v. Board of Directors’ performance evaluation; <br />	vi. Shares held by Directors and their related parties; and <br />	vii. Details of directors, shareholders and their related parties who own five per cent and above of the banks’ shares as well as other beneficial owners who, in concert with others, control the bank.",
                isRequired: true,
                uniqueName: "R26Q1(a)",
                entryName: "r26q1a",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    " Disclosure in the annual report shall include, but not limited to, material information on: <br /> <br />Corporate Governance Structure: <br /> i. Appointment and tenure of directors; <br /> ii. Composition of Board Committees including names of chairmen and members of each Committee; <br /> iii. Total number of Board and its Committees meetings held in the financial year and attendance by each director; <br /> iv. A summary of details of training and induction for directors; and <br /> v. In addition to (i) – (iv) above, for NIBs: <br /> a. Shariah governance mechanism; <br /> b. Statement on compliance with Internal Shariah Review Mechanism; <br /> c. Composition of the ACE and the number of meetings attended by each member; <br /> d. ACE certification of compliance with principles of Islamic finance. ",
                isRequired: true,
                uniqueName: "R26Q1(b)",
                entryName: "r26q1b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question:
                    "Disclosure in the annual report shall include, but not limited to, material information on: <br /> <Risk management:<br />i. All significant risks including risks specific to NIBs; and<br />ii. Risk management practices indicating the Board’s responsibility for the entire process of risk management as well as a summary of the lapses observed by external auditors, if any.",
                isRequired: true,
                uniqueName: "R26Q1(c)",
                entryName: "r26q1c",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question:
                    "Disclosure in the annual report shall include, but not limited to, material information on: <br /> <br /> Information on strategic modification to the core business of the bank.",
                isRequired: true,
                uniqueName: "R26Q1(d)",
                entryName: "r26q1d",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 4,
            },
            {
                question:
                    "Disclosure in the annual report shall include, but not limited to, material information on: <br /> <br /> All regulatory/supervisory contraventions, sanctions and penalties during the year under review and infractions uncovered through whistle blowing.",
                isRequired: true,
                uniqueName: "R26Q1(e)",
                entryName: "r26q1e",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 5,
            },
            {
                question: "Disclosure in the annual report shall include, but not limited to, material information on: <br /> <br /> Capital Structure/Adequacy",
                isRequired: true,
                uniqueName: "R26Q1(f)",
                entryName: "r26q1f",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 6,
            },
            {
                question: "Disclosure in the annual report shall include, but not limited to, material information on: <br /> <br /> Opening and closing of branches/outlets.",
                isRequired: true,
                uniqueName: "R26Q1(g)",
                entryName: "r26q1g",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 7,
            },
            {
                question:
                    "Disclosure in the annual report shall include, but not limited to, material information on: <br /> <br /> Any service contracts and other contractual relationships with related parties.",
                isRequired: true,
                uniqueName: "R26Q1(h)",
                entryName: "r26q1h",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 8,
            },
            {
                question: "Disclosure in the annual report shall include, but not limited to, material information on: <br /> <br />Frauds and Forgeries.",
                isRequired: true,
                uniqueName: "R26Q1(i)",
                entryName: "r26q1i",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 9,
            },
            {
                question: "Disclosure in the annual report shall include, but not limited to, material information on: <br /> <br /> Contingency Planning Framework",
                isRequired: true,
                uniqueName: "R26Q1(j)",
                entryName: "r26q1j",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 10,
            },
            {
                question: "Disclosure in the annual report shall include, but not limited to, material information on: <br /> <br /> Contingent Assets and Liabilities (off balance sheet items) ",
                isRequired: true,
                uniqueName: "R26Q1(k)",
                entryName: "r26q1k",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 11,
            },
            {
                question:
                    "Disclosure in the annual report shall include, but not limited to, material information on: <br /> <br /> The details of parent/holding institutions, subsidiaries, affiliates, joint ventures and Special Purpose Enterprises/Vehicles (SPEs/SPVs), where applicable.",
                isRequired: true,
                uniqueName: "R26Q1(l)",
                entryName: "r26q1l",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 12,
            },
            {
                question:
                    "Disclosure in the annual report shall include, but not limited to, material information on: <br /> <br /> Any matter not specifically mentioned in this Guidelines, but which may materially affect the financial position or going concern status of the bank.",
                isRequired: true,
                uniqueName: "R26Q1(m)",
                entryName: "r26q1m",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 13,
            },
            {
                question:
                    "Disclosure in the annual report shall include, but not limited to, material information on: <br /> <br /> NIBs in addition to all the above shall make disclosures on: <br />a. Returns paid to PSIAHs and amount of income smoothing (if any) <br />b. NPI and its disposal (where applicable).",
                isRequired: true,
                uniqueName: "R26Q1(n)",
                entryName: "r26q1n",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 14,
            },
            {
                question:
                    "To foster good corporate governance, banks are encouraged to make robust disclosures beyond the statutory requirements in BOFIA 2020 and other applicable laws and regulations",
                isRequired: true,
                uniqueName: "R26Q2",
                entryName: "r26q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Annual reports of NIBs are required to contain certification of the ACE that the operations of the NIB are in line with the principles of Islamic finance.",
                isRequired: true,
                uniqueName: "R26Q3",
                entryName: "r26q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "27.0 RETURNS",
        description: "",
        questions: [
            {
                question:
                    "Banks shall submit to the Director Banking Supervision Department, CBN, periodic returns as specified in the extant Guidelines for Licensing and Regulation of Financial Holding Companies in Nigeria.",
                isRequired: true,
                uniqueName: "R27Q1",
                entryName: "r27q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "When required, every bank shall render electronic submission of each of these regulatory returns to a dedicated web portal as may be prescribed by the FRC.",
                isRequired: true,
                uniqueName: "R27Q2",
                entryName: "r27q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "Other documentation",
        description: "Add any other document to support/augment your report. Clicking “upload document” would allow you provide more context to each document.",
        questions: [
            {
                question: "",
                isRequired: true,
                uniqueName: "",
                entryName: "documentation",
                orderIndex: 1,
                dataType: 4,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
];
