export default [
    {
        name: "",
        title: "1.0 BOARD OF DIRECTORS AND MANAGEMENT",
        description: "",
        questions: [
            {
                question: "The Structure and Composition of the Board shall be such as to maintain the confidence of the Shareholders and Management.",
                isRequired: true,
                uniqueName: "R1Q1(a)",
                entryName: "r1q1a",
                header: "Structure and Composition of the Board",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "The insurance/reinsurance company shall have not less than seven (7) members and not more than fifteen (15) members on its Board. ",
                isRequired: true,
                uniqueName: "R1Q1(b)",
                entryName: "r1q1b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question: "The Board shall consist of Executive and Non-Executive Directors out of which not more than 40% of the members shall be in the executive capacity.",
                isRequired: true,
                uniqueName: "R1Q1(c)",
                entryName: "r1q1c",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question:
                    "In addition to the provision of Principle 2.7 of the NCCG 2018, no one person shall occupy the position of Chairman and Managing Director/CEO in related insurance companies at the same time.",
                isRequired: true,
                uniqueName: "R1Q1(d)",
                entryName: "r1q1d",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 4,
            },
            {
                question: "No two members of the same family (nuclear and extended) shall occupy the position of the Chairman and Managing Director/Chief Executive of any Insurance Company. ",
                isRequired: true,
                uniqueName: "R1Q1(e)",
                entryName: "r1q1e",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 5,
            },
            {
                question: "No member of the Board shall draw remunerations beyond the normal entitlements for such appointment.",
                isRequired: true,
                uniqueName: "R1Q1(f)",
                entryName: "r1q1f",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 6,
            },
            {
                question: "Membership of the Board shall include at least one Independent Director, who does not represent any particular shareholding interest nor hold any business interest. ",
                isRequired: true,
                uniqueName: "R1Q1(g)",
                entryName: "r1q1g",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 7,
            },
            {
                question: "Non-Executive Directors shall not be re-nominated and appointed for more than 3 terms of 3 years each.",
                isRequired: true,
                uniqueName: "R1Q1(h)",
                entryName: "r1q1h",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 8,
            },
            {
                question: "Appointment of Non-Executive Directors shall be decided by the Board through a defined selection process.",
                isRequired: true,
                uniqueName: "R1Q1(i)",
                entryName: "r1q1i",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 9,
            },
            {
                question: "The Chief Executive Officer of the Company shall be the person approved by the Commission and shall be a member of the Board throughout his/her tenure",
                isRequired: true,
                uniqueName: "R1Q1(j)",
                entryName: "r1q1j",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 10,
            },
            {
                question: "Each Executive Director of the Company shall be a person approved by the Commission and shall be a member of the Board throughout his/her tenure.",
                isRequired: true,
                uniqueName: "R1Q1(k)",
                entryName: "r1q1k",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 11,
            },
            {
                question:
                    "For purposes of succession, every Insurance/Reinsurance Company shall have an Executive Director (Technical) who shall have minimum qualification and experience equivalent to that of Chief Executive Officer prescribed by the extant Rules. ",
                isRequired: true,
                uniqueName: "R1Q1(l)",
                entryName: "r1q1l",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 12,
            },
            {
                question: "To represent minority interests, all Public Limited Liability Insurance/Reinsurance Companies shall provide a seat for minority shareholder on the Board.",
                isRequired: true,
                uniqueName: "R1Q1(m)",
                entryName: "r1q1m",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 13,
            },
            {
                question: "All nominated members of the Board shall complete and file with the Commission, Personal History Statement (PHS) Form at the point of application to the Commission.",
                isRequired: true,
                uniqueName: "R1Q1(n)",
                entryName: "r1q1n",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 14,
            },
            {
                question:
                    "All insurance institutions shall operate independently and the Board and Management shall be responsible and accountable for the conduct /operations of the company irrespective of any relationship with other companies or group.",
                isRequired: true,
                uniqueName: "R1Q1(o)",
                entryName: "r1q1o",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 15,
            },
            {
                question:
                    "In selecting a Director, the following factors shall be considered:  <br />i.	Relevance of the candidate’s experience and knowledge in the insurance industry.  <br />ii.	Record of diligence, integrity, willingness and ability to be independent and objective as well as to serve actively as a director.  <br />iii.	Limited insider relationships and links with competitors.  <br />iv.	A track record of success in business with familiarity and experience in performing the role of a Board member.",
                isRequired: true,
                uniqueName: "R1Q2",
                entryName: "r1q2",
                header: "Qualities of the Members",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Board shall conduct the business in line with high ethical and sound insurance best practices.",
                isRequired: true,
                uniqueName: "R1Q3(a)",
                entryName: "r1q3a",
                header: "Duties of the Board",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "Board members shall act on a fully informed basis, in good faith, with due diligence and care, and in the best interest of the company and the shareholders.",
                isRequired: true,
                uniqueName: "R1Q3(b)",
                entryName: "r1q3b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question: "Where Board decisions may affect shareholder groups differently, the Board shall treat all shareholders fairly.",
                isRequired: true,
                uniqueName: "R1Q3(c)",
                entryName: "r1q3c",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question: "The Board shall exercise objective independent judgment on corporate affairs.",
                isRequired: true,
                uniqueName: "R1Q3(d)",
                entryName: "r1q3d",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 4,
            },
            {
                question: "It shall be the duty of the Board to formulate corporate policies, profile and monitor risks and impose internal controls in the case of group structure.",
                isRequired: true,
                uniqueName: "R1Q3(e)",
                entryName: "r1q3e",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 5,
            },
            {
                question:
                    "The Board must ensure it is not subject to undue influence from senior management or other parties and that it has access to all relevant information about the company at all times.",
                isRequired: true,
                uniqueName: "R1Q3(f)",
                entryName: "r1q3f",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 6,
            },
            {
                question:
                    "The responsibilities of the Board shall include: <br /> Reviewing corporate strategy, major plans of actions, risk policy, business plans, setting performance objectives, monitoring implementation and corporate performance and overseeing major capital expenditures and acquisitions.",
                isRequired: true,
                uniqueName: "R1Q4(a)",
                entryName: "r1q4a",
                header: "Responsibilities of the Board",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "The responsibilities of the Board shall include:  <br /> Selecting, appointing, compensating, monitoring and where necessary, replacing Executives/Management and putting in place a succession plan for the company.",
                isRequired: true,
                uniqueName: "R1Q4(b)",
                entryName: "r1q4b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question: "The responsibilities of the Board shall include:  <br />	Monitoring the effectiveness of the governance practices and make changes as may be necessary. ",
                isRequired: true,
                uniqueName: "R1Q4(c)",
                entryName: "r1q4c",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question:
                    "The responsibilities of the Board shall include:  <br /> Monitoring and managing potential conflicts of interest of management, Board members and shareholders, including misuse of corporate assets and abuses in related party transactions.",
                isRequired: true,
                uniqueName: "R1Q4(d)",
                entryName: "r1q4d",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 4,
            },
            {
                question: "The responsibilities of the Board shall include: <br /> Supervising and monitoring execution of policies and providing direction for the management.",
                isRequired: true,
                uniqueName: "R1Q4(e)",
                entryName: "r1q4e",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 5,
            },
            {
                question: "The responsibilities of the Board shall include: <br /> Monitoring potential risks within the company including recognizing and encouraging honest whistle blowing.",
                isRequired: true,
                uniqueName: "R1Q4(f)",
                entryName: "r1q4f",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 6,
            },
            {
                question: "The responsibilities of the Board shall include: <br /> Overseeing the process of disclosure and communication in the company.",
                isRequired: true,
                uniqueName: "R1Q4(g)",
                entryName: "r1q4g",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 7,
            },
            {
                question:
                    "The Board shall put in place the following:  <br /> An organizational structure that effectively meets the needs of insurance business with job descriptions, authority levels and working relationships for members of the management.",
                isRequired: true,
                uniqueName: "R1Q5(a)",
                entryName: "r1q5a",
                header: "Organizational Structure",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question: "The Board shall put in place the following: <br />    Well-defined responsibilities of the top Management of a company.",
                isRequired: true,
                uniqueName: "R1Q5(b)",
                entryName: "r1q5b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question: "The Board shall put in place the following: <br />   The company’s organogram shall be in line with the format approved by the Commission.",
                isRequired: true,
                uniqueName: "R1Q5(c)",
                entryName: "r1q5c",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question:
                    "The Board shall put in place the following: <br /> Effective human resources management system that ensures staff welfare and objective assessment of individuals with more weight given to demonstrated performance on the job and individual career developments.",
                isRequired: true,
                uniqueName: "R1Q5(d)",
                entryName: "r1q5d",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 4,
            },
            {
                question:
                    "The Board shall put in place the following: <br />    Management control system that focuses on management processes including operational planning and control, computerization, productivity and management information system development.",
                isRequired: true,
                uniqueName: "R1Q5(e)",
                entryName: "r1q5e",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 5,
            },
            {
                question:
                    "The Board shall put in place the following: <br />   Good security system with particular emphasis on checks and balances, dual control on operations and greater discipline to reduce incidence of malpractices.",
                isRequired: true,
                uniqueName: "R1Q5(f)",
                entryName: "r1q5f",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 6,
            },
            {
                question: "The Board shall put in place the following: <br />    Clearly thought-out policies and strategies on marketing, business acquisition and claims administration. ",
                isRequired: true,
                uniqueName: "R1Q5(g)",
                entryName: "r1q5g",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 7,
            },
            {
                question: "Directors are expected to preserve and enhance shareholders’ value.",
                isRequired: true,
                uniqueName: "R1Q6(a)",
                entryName: "r1q6a",
                header: "Governance and Remuneration of Directors",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 1,
            },
            {
                question:
                    "The Board shall be empowered to make the needed time commitments, suitably compensated, receive proper minutes of meetings and be provided with appropriate information to enable them perform their functions and evaluate the performance of the management.",
                isRequired: true,
                uniqueName: "R1Q6(b)",
                entryName: "r1q6b",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 2,
            },
            {
                question: "Directors shall be pro-active and diligent in performing their assigned responsibilities.",
                isRequired: true,
                uniqueName: "R1Q6(c)",
                entryName: "r1q6c",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 3,
            },
            {
                question: "The Board of Directors will determine remuneration for Directors and seek approval at the AGM.",
                isRequired: true,
                uniqueName: "R1Q6(d)",
                entryName: "r1q6d",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 4,
            },
        ],
    },
    {
        name: "",
        title: "2.0 MEETINGS OF THE BOARD",
        description: "",
        questions: [
            {
                question: "Each member shall attend not less than 75% of the meetings of the Board annually.",
                isRequired: true,
                uniqueName: "R2Q1",
                entryName: "r2q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "Report of attendance shall form part of the report to be presented at the AGM.",
                isRequired: true,
                uniqueName: "R2Q2",
                entryName: "r2q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "3.0 COMMITTEES OF THE BOARD",
        description: "",
        questions: [
            {
                question: "In addition to Principle 11.1.6 of NCCG 2018, the Board shall have Committees responsible for: <br />  Finance, Investment and General Purpose 	",
                isRequired: true,
                uniqueName: "R3Q1",
                entryName: "r3q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "In addition to Principle 11.1.6 of NCCG 2018, the Board shall have Committees responsible for: <br />   Compliance ",
                isRequired: true,
                uniqueName: "R3Q2",
                entryName: "r3q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "4.0. INDUCTION AND CONTINUING EDUCATION FOR BOARD MEMBERS",
        description: "",
        questions: [
            {
                question:
                    "The Company shall, at least once in a year, arrange relevant training on insurance principles and practice, Director’s responsibilities and liabilities, and update on insurance market to which attendance by members of the Board shall be mandatory.",
                isRequired: true,
                uniqueName: "R4Q1",
                entryName: "r4q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Commission may from time to time arrange trainings to which attendance by members of the Board shall be expected.",
                isRequired: true,
                uniqueName: "R4Q2",
                entryName: "r4q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "5.0 BOARD EVALUATION",
        desription: "",
        questions: [
            {
                question: "There shall be annual Board performance evaluation to be carried out by an Independent consultant to be appointed at the AGM.",
                isRequired: true,
                uniqueName: "R5Q1",
                entryName: "r5q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Evaluation Report shall be forwarded to the Commission before end of first quarter of the succeeding year. ",
                isRequired: true,
                uniqueName: "R5Q2",
                entryName: "r5q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "6.0 INTERNAL AUDIT",
        description: "",
        questions: [
            {
                question: "Each Insurance/Reinsurance Company under an insurance group shall have a separate Internal Audit Unit.",
                isRequired: true,
                uniqueName: "R6Q1",
                entryName: "r6q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Internal Audit Report of each Insurance/Reinsurance Company shall be filed with the Commission every quarter.",
                isRequired: true,
                uniqueName: "R6Q2",
                entryName: "r6q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Internal Audit Unit shall be headed by a professionally qualified Accountant not below the rank of an Assistant General Manager (AGM) or its equivalent.",
                isRequired: true,
                uniqueName: "R6Q3",
                entryName: "r6q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The report of the external assessment of Internal Audit functions shall be submitted to the Commission not later than 2nd quarter of the succeeding year. ",
                isRequired: true,
                uniqueName: "R6Q4",
                entryName: "r6q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "7.0 EXTERNAL AUDITORS",
        description: "",
        questions: [
            {
                question: "Insurance/Reinsurance Companies shall seek and obtain approval of the Commission prior to appointment of External Auditors.",
                isRequired: true,
                uniqueName: "R7Q1",
                entryName: "r7q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The tenure of an appointed External Auditor shall be for a period of four (4) years in the first instance and may be re- appointed for a further period of four (4) years and no more.",
                isRequired: true,
                uniqueName: "R7Q2",
                entryName: "r7q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The audit teams shall be rotated at least once every two years.",
                isRequired: true,
                uniqueName: "R7Q3",
                entryName: "r7q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "8.0 CONFLICT OF INTEREST",
        description: "",
        questions: [
            {
                question:
                    "Each Director and employee of an Insurance/Reinsurance Company shall formally disclose to the Board of Directors or Shareholders his/her interest whatsoever in any:  <br /> i.	Reinsurance Company;  <br /> ii.	Insurance Company;  <br /> iii.	Takaful Insurance Company;  <br /> iv.	Micro insurance Company;  <br /> v.	Insurance Broking firm;  <br /> vi.	Loss Adjusting firm;  <br /> vii.	Actuarial firm;  <br /> viii.	Accounting/Taxation firm/Audit firms; and ix. Legal and Secretarial firm.",
                isRequired: true,
                uniqueName: "R8Q1",
                entryName: "r8q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "Payment of commissions and/or fees to any of the above listed in which any Director or an employee has interest whatsoever shall be fully documented and the interest therein fully disclosed to the Board/Shareholders.",
                isRequired: true,
                uniqueName: "R8Q2",
                entryName: "r8q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "9.0 RIGHTS OF THE SHAREHOLDERS",
        description: "",
        questions: [
            {
                question:
                    "The shareholders of insurance and reinsurance companies in Nigeria shall have rights to:  <br /> i.	Participate in, and be sufficiently informed on, decisions concerning fundamental corporate changes including amendments to the statutes, or articles of incorporation or similar governing documents of the company; the authorization of additional shares; and extraordinary transactions, including the transfer of all or substantially all assets, that in effect result in the sale of the company.  <br /> ii.	Make their views known on the remuneration policy for Board members and key executives of the company. <br /> iii.	Consult with each other on issues concerning their basic shareholders’ rights subject to exceptions to prevent abuse.  <br />  iv.	Receive dividends when declared.",
                isRequired: true,
                uniqueName: "R9Q1",
                entryName: "r9q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "10.0 BUSINESS CONDUCT & ETHICS",
        description: "",
        questions: [
            {
                question:
                    "Directors and Management are to ensure that high ethical standards for the conduct of insurance business are maintained at all times, including:  <br />  i.	Prompt payment of valid claims and institute a best practise alternative dispute resolution mechanism. <br />  ii.	Payment of commissions and/or fees to insurance intermediaries in which the Chief Executive Officer, directors and employee have interest.  <   iii.	Disclosing to the Board and Management any matter in which the Chief Executive Officer, Director(s) or an Employee has interest whatsoever. Any such interest shall be fully documented.",
                isRequired: true,
                uniqueName: "R10Q1",
                entryName: "r10q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "11.0 DISCLOSURE REQUIREMENTS",
        description: "",
        questions: [
            {
                question:
                    "The Annual Report shall sufficiently disclose the following information: <br />  The manner of its compliance with, or explain any deviation from NCCG 2018 and/or this Guideline.",
                isRequired: true,
                uniqueName: "R11Q1",
                entryName: "r11q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Annual Report shall sufficiently disclose the following information: <br />  The Board Meeting’s attendance record of members.",
                isRequired: true,
                uniqueName: "R11Q2",
                entryName: "r11q2",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question: "The Annual Report shall sufficiently disclose the following information: <br />  The deviation from applicable Corporate Governance Standards",
                isRequired: true,
                uniqueName: "R11Q3",
                entryName: "r11q3",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
            {
                question:
                    "The Annual Report shall sufficiently disclose the following information: <br /> The contraventions on which penalties have been imposed by the Commission and/or any other regulatory/supervisory body during the accounting year.",
                isRequired: true,
                uniqueName: "R11Q4",
                entryName: "r11q4",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "12.0 WHISTLE BLOWING",
        description: "",
        questions: [
            {
                question: "Where a whistle blower has been subjected to any detriment, he shall have the right and be given the opportunity to present a complaint to the Commission.",
                isRequired: true,
                uniqueName: "R12Q1",
                entryName: "r12q1",
                orderIndex: 1,
                dataType: 16,
                selectOptions: ["Aligned", "Not Aligned", "Explained"],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
    {
        name: "",
        title: "Other documentation",
        description: "",
        questions: [
            {
                question: "",
                isRequired: true,
                uniqueName: "",
                entryName: "documentation",
                orderIndex: 1,
                dataType: 4,
                selectOptions: [],
                allowedDocType: [".pdf", ".doc", ".docx"],
                isFlaggable: true,
                innerOrderIndex: 0,
            },
        ],
    },
];
